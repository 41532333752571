/* global window, document */
import {isObject} from 'lodash';

const getVehiclePicker = () => import(/* webpackChunkName: "get-vehicle-picker" */ 'public/js/components/vehicle-picker.js');

// A list of classes used to open the vehicle picker
const vehiclePickerSelectors = [
	'.vehiclepicker_classic',
	'.vehiclepicker',
	'.vehiclepicker_link',
	'.vehiclepicker-link',
];

const handleOpenVehiclePicker = ({ currentTarget }, props = null) => {
	const pickerData = props || currentTarget.dataset;
	//dynamically import the vehicle picker class
	getVehiclePicker().then((module) => {
		module.getDataAndOpenVehiclePicker({ currentTarget }, pickerData);
	});
};

/* Call vehicle picker on select elements
.*-----------------------------------------*/
document.querySelectorAll(vehiclePickerSelectors.join(', ')).forEach((elem) => {
	if(!elem.attachedVehiclePickerEvent) {
		elem.addEventListener('click', handleOpenVehiclePicker);
		elem.attachedVehiclePickerEvent = true;
	}
});

//Moved this out from the vehicle picker js file to account for scenarios where this is called from custom html modules
window.openVehiclePicker = window.openVehiclePickerRedirect = (make, model = null, year = null, trim = null, engine = null, redirect = null) => {
	if (isObject(make)) {
		const { makeInfo, model, year, trim, engine, redirect } = make;
		handleOpenVehiclePicker({}, {makeInfo, model, year, trim, engine, redirect});
	} else {
		handleOpenVehiclePicker({}, {make, model, year, trim, engine, redirect});
	}
};

window.handleOpenVehiclePicker = handleOpenVehiclePicker;
